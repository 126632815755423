<template>
  <div>
    <a-modal
      width="1200px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增流量池总包"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-row>
          <!--基本信息， 周期容量设置-->
          <a-col :span="12">
            <span class="item-title">基本信息</span>
            <a-form-item label="总包名称">
              <a-input
                v-decorator="['name', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { required: true, message: '请输入总包名称' },
                    { max: 100, message: '最多100个字符' },
                  ]
                }]"
              />
            </a-form-item>

            <a-form-item :label="`总包类型`">
              <a-select
                v-decorator="['product_type',
                              { initialValue: '',
                                rules: [{ required: true, message: '请选择总包类型' }]
                              },
                ]"
                allow-clear
              >
                <a-select-option value="traffic_pool_single">
                  单一运营商流量总包
                </a-select-option>
                <a-select-option value="traffic_pool_mult">
                  融合运营商流量总包
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="总包容量"
              required="required"
              style="margin-bottom: 0px"
            >
              <a-input-group compact>
                <a-form-item
                  :style="{ display: 'inline-block', width: '80%' }"
                >
                  <a-space>
                    <a-input-number
                      :min="0.00"
                      :max="10000000"
                      style="width: 280px;"
                      v-decorator="[ 'package_capacity', {
                        rules: [
                          { required: true, message: '请输入总包容量' },
                          packageCapacityTypeRule
                        ],
                      }]"
                    />
                  </a-space>
                </a-form-item>

                <a-form-item :style="{ display: 'inline-block', width: '20%' }">
                  <a-select
                    @change="handleCapacityUnitChange"
                    v-decorator="['capacity_unit', { initialValue: 'mb' }]"
                    style="width: 65px"
                  >
                    <a-select-option
                      v-for="option in capacityUnitOptions"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-input-group>
            </a-form-item>

            <div>
              <span class="item-title">其它设置</span>

              <a-form-item label="允许自订">
                <a-checkbox
                  v-decorator="['is_allow_self_subscribe', { valuePropName: 'checked' }]"
                /> 允许CMP订购
              </a-form-item>
            </div>
          </a-col>

          <!--价格设置-->
          <a-col :span="12">
            <span class="item-title">价格设置</span>

            <a-row style="margin-top: 10px; margin-bottom: 10px" :gutter="24">
              <a-col :span="favorablePriceSpanSize">
                周期数*
              </a-col>
              <a-col :span="favorablePriceSpanSize">
                标准价(元)*
              </a-col>
              <a-col :span="favorablePriceSpanSize">
                底价(元)*
              </a-col>
            </a-row>

            <a-row
              :gutter="24"
              v-for="(k) in form.getFieldValue('keys')"
              :key="k"
            >
              <a-col :span="favorablePriceSpanSize">
                <a-form-item>
                  <a-input-number
                    :min="1"
                    :max="10000000"
                    style="width: 120px"
                    disabled
                    @change="validateFavorablePriceCycles"
                    v-decorator="[`base_product_favorable_prices[${k}].cycles`, {
                      initialValue: k === 0 ? 1 : undefined,
                      rules: [
                        { required: true, message: '请输入周期数' },
                        { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                        { validator: (rule, value, callback) => checkCycles(rule, value, callback, k) }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="favorablePriceSpanSize">
                <a-form-item>
                  <a-input-number
                    :min="0"
                    :max="10000000"
                    :step="0.01"
                    :precision="2"
                    style="width: 120px"
                    v-decorator="[`base_product_favorable_prices[${k}].distributor_price`, {
                      rules: [
                        { required: true, message: '请输入标准价' },
                        { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                      ]
                    }]"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="favorablePriceSpanSize">
                <a-input-group compact>
                  <a-form-item>
                    <a-space>
                      <a-input-number
                        :min="0"
                        :max="10000000"
                        :step="0.01"
                        :precision="2"
                        style="width: 120px"
                        v-decorator="[`base_product_favorable_prices[${k}].floor_price`, {
                          rules: [
                            { required: true, message: '请输入底价' },
                            { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                          ]
                        }]"
                      />

                      <a-icon
                        :style="{ display: 'inline-block'}"
                        class="dynamic-delete-button"
                        type="minus-circle-o"
                        v-show="form.getFieldValue('keys').length > 1"
                        :disabled="form.getFieldValue('keys').length === 1"
                        @click="() => removeSetPriceItem(k)"
                      />
                    </a-space>
                  </a-form-item>
                </a-input-group>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { createBaseProduct } from '@/api/base_product'

export default {
  name: 'NewBaseProduct',
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'base_product', onValuesChange: this.onValuesChange }),
      submitting: false,
      productType: undefined,
      capacityUnitOptions: [
        { name: 'MB', value: 'mb' },
        { name: 'GB', value: 'gb' },
        { name: '次', value: 'count' }
      ],
      packageCapacityTypeRule: {
        type: 'number',
        message: '请输入 0.01 - 10000000 之间的数字'
      },
      priceIndex: 1,
      favorablePriceSpanSize: 8 // 价格设置span大小
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
  },
  methods: {
    onValuesChange(props, values) {
      if (values.product_type !== undefined) {
        this.productType = values.product_type
      }
    },

    checkCycles(rule, value, callback, k) {
      if (!value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback()
        return
      }
      const preCycles = this.form.getFieldValue(`base_product_favorable_prices[${k - 1}].cycles`)
      if (preCycles && preCycles >= value) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('应比上个周期数大!')
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    // 校验优惠价周期
    validateFavorablePriceCycles() {
      this.form.getFieldValue('keys').forEach((k) => {
        if (this.form.getFieldValue(`base_product_favorable_prices[${k}].cycles`)) {
          this.$nextTick(() => {
            this.form.validateFields([`base_product_favorable_prices[${k}].cycles`], { force: true })
          })
        }
      })
    },

    // 处理 容量单位 变更
    handleCapacityUnitChange(value) {
      if (value === 'count') {
        this.packageCapacityTypeRule = {
          type: 'integer',
          message: '请输入 1 - 10000000 之间的整数'
        }
      } else {
        this.packageCapacityTypeRule = {
          type: 'number',
          message: '请输入 0.01 - 10000000 之间的数字'
        }
      }

      // 如果已经输入了值，则触发校验
      if (this.form.getFieldValue('package_capacity')) {
        this.$nextTick(() => {
          this.form.validateFields(['package_capacity'], { force: true })
        })
      }
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      this.form.validateFields((err, values) => {
        if (!err) {
          const data = {
            ...values,
            base_product_favorable_prices: values.base_product_favorable_prices.filter(key => key)
          }

          createBaseProduct(data).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        } else {
          this.submitting = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.item-title {
  color: @primary-color;
}
</style>
